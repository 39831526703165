.bigCalendar {
.rbc-toolbar {
  .rbc-btn-group {
    button {
      @extend %transition;
      padding: 0 23px;
      height: 40px;
      font-weight: 500;
      display: inline-block;
      line-height: 34px;
      border-radius: 4px;
      font-size: 14px;
      position: relative;
      z-index: 2;
      font-weight: 600;
      letter-spacing: .2px;
      text-transform: uppercase;
        &:hover {
          background: #ffffff;
          color: $theme-color;
        }

        &.rbc-active{
          color: $theme-color;
        }
    }
  }
  .rbc-toolbar-label{
      height: 40px;
      display: inline-block;
      line-height: 34px;
      font-size: 22px;
      font-weight: 600;
      position: relative;
      z-index: 2;
      letter-spacing: .2px;
      color: $theme-color;
      text-transform: uppercase;
    }
  }
  .rbc-time-view, .rbc-month-view{

        .rbc-row {
          .rbc-header {
            height: 35px;
            display: inline-block;
            line-height: 30px;
            font-size: 14px;
            position: relative;
            z-index: 2;
            letter-spacing: .2px;
            text-transform: uppercase;

          }
          .rbc-today {
            font-weight: 600;
            color: $theme-color;

          }

        } 
        .rbc-time-header-cell{

        }
        .workshop-event {
          background-color: #001c43;
        }
      
        .care-event {
          background-color: $color-2;
        }
      
        .other-event {
          background-color: $color-Green;
        }
      
        .course-event {
          background-color: $color-4;
        }
  }
        .rbc-event {
          background-color: #001c43;
          border:none;
          @extend %transition;
          @extend %posrelative;
          @media #{$sm-layout} {
            padding: 9px 25px;
          }
          &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
          }
          &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
  }
  .rbc-addons-dnd-resize-ns-anchor {
    display: none;
  }
  .rbc-time-slot {
    width:70px;
    text-align: center;
    .rbc-label{
      font-weight: 600;
    }
  }

  .rbc-current-time-indicator {
    color: $theme-color;
    height: 3px;
    background-color: $theme-color;
  }

  .rbc-agenda-date-cell {
    @extend %transition;
    padding: 0 23px;
    height: 40px;
    line-height: 34px;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    z-index: 2;
    letter-spacing: .2px;
    text-transform: uppercase;
  }
}