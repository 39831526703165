.pickList {

    .listBox {
        width: 50%;
        display: flex;
        flex-direction: column;
        .container {
          display: block;
          width: 100%;
          border: 2px solid rgba(0,0,0,.1);
          border-radius: 5px;
          padding-top: 8px;
          color: #1f1f25;
          font-size: 15px;
          letter-spacing: .1px;        
        }
    }

        .item {
            display: flex;
            cursor: pointer;
            line-height: 25px;
            outline: none;
            box-sizing: border-box;
            > span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 5px 0 2px 10px;
            }
          }
          
          .item:hover {
            background-color: $color-1;
            color: #FFF ;
            border-right: 1px solid $color-1;
          }

    .listHeader  {
        display: flex;
        border-bottom: 1px solid var(--mslb-border-color) !important;
        background: transparent;
        border: none;
        font-size: 11px;
        width: 100%;
        &:active,
        &:focus {
          outline: none !important;
        }
    }
        .search {
            flex-grow: 1;
            box-sizing: border-box;
            background-color: transparent;
            border: none;
            padding: 2px 4px;
            min-width: 0;
            &:active,
            &:focus {
              outline: none !important;
            }
          }


        .info {
            flex-grow: 1;
            height: 40px;
            line-height: 30px;
            padding: 0 0.5em 0 0.5em;
            background-color: transparent;
            cursor: default;
            white-space: nowrap;
          }

  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;

}