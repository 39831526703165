.filter {
    &.rn-button-style--2 {
        color: #c6c9d8;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 2px solid #b1b4c1;
        padding: 15px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        transition: 0.3s;
        @media #{$sm-layout} {
            padding: 9px 25px;
        }

        &.rn-btn-dark {
            border: 2px solid #1f1f25;
            color: #1f1f25;
            &:hover {
                background: #1f1f25;
                color: #fff;
            }
        }

        &.border-opacity-half{
            border-color: rgba(31, 31, 37, 0.1);
        }

        &.rn-btn-small {
            padding: 10px 20px;
        }

        &.btn-primary-color {
            &:hover {
                border: 2px solid $theme-color;
                background: $theme-color;
                color: #ffffff;
            }
        }

        &.btn-solid {
            border: 2px solid $theme-color;
            background: $theme-color;
            color: #ffffff;
        }

        &.btn-solid-border {
            border: 2px solid $theme-color;
            background: transparent;
            color: $theme-color;
            &:hover{
                border: 2px solid $theme-color;
                background: $theme-color;
                color: #ffffff;
                transform: translateY(-5px);
            }
        }

        &.rn-btn-small-2 {
            padding: 11px 15px;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 13px;
        }

        &.btn-solid {
            background: $theme-color;
            color: #ffffff;
        }

        &:hover{
            background: transparent;
            color: $theme-color;
            box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
            transform: translateY(-5px);
        }
    }
    &.btn-transparent{
        cursor: pointer;
        color: #1f1f25;
        @extend %transition;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding-bottom: 3px;
        &:hover{
            color: $theme-color;
        }
        &::after{
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 40px;
            height: 2px;
            background: $theme-color;
            opacity: 0.75;
            @extend %transition;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    }
}