
/*--------------------------------
   Vandor Dashboard Page
-----------------------------------*/
.vendor_top_box {
    text-align: center;
    border: 1px solid #f79837;
    padding: 20px 0;
    box-shadow: 3px 5px 7px 1px #00000014;
    transition: .4s all ease-in-out;
}

.vendor_top_box:hover {
    box-shadow: none;
}

.vendor_top_box h2 {
    font-weight: 600;
    color: var(--main-theme-color);
}

.vendor_top_box h4 {
    margin-bottom: 0;
    font-weight: 400;
    padding-top: 5px;
}

.mychart_area {
    padding-top: 30px;
}


.pending_table {
    background: #fff;
    box-shadow: 3px 3px 6px 3px #00000008;
    overflow: hidden;
}

.pending_table thead tr th {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.pending_table tbody tr {
    /* border-bottom: 1px solid #eee; */
    margin-bottom: 10px;
}

.pending_table tbody tr td {
    vertical-align: middle;
    text-transform: capitalize;
    text-align: center;
}

.btn_right_table {
    text-align: right;
    margin-top: 30px;
}

/* --Vendor Profile-- */
.profils_details_vendor {
    display: flex;
}

.profils_details_vendor .profile_left {
    width: 150px;
    margin-right: 15px;
}

.profils_details_vendor .profile_left {
    display: flex;
    align-items: center;
}

.vendors_profiles ul li h4 {
    font-size: 16px;
    color: #787878;
    font-weight: 400;
}

.vendors_profiles .btn_left_table {
    padding-top: 10px;
}
