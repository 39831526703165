// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-quill/dist/quill.snow.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
