
.dashboard_tab_button {
    margin-bottom: 50px;
    li a {
        border: 1px solid #E8E8E8;
        border-bottom: none;
        color: #212121;
        font-weight: 500;
        font-size: 15px;
        display: block;
        padding: 18px 15px;
        text-transform: uppercase;
        i {
            font-size: 14px;
            text-align: center;
            width: 25px;
        }
    }
    li a:last-child {
        border-bottom: 1px solid #E8E8E8;
    }
    li a.active, li a:hover {
        background-color: $color-1;
        border-color: $color-1;
        color: #fff;
    }
}

.view {
    color: $color-1;;
}

.deleteButton {
    margin-top: -5px;
    font-size: 18px;
    color: $theme-color-red;
    cursor: pointer;
}

.editButton {
    margin-top: -5px;
    color: $color-3;
    cursor: pointer;
    font-size: 18px;
}

.enableButton {
    margin-top: -5px;
    font-size: 18px;
    color: $color-Green;
    cursor: pointer;
}

.client-link{
    @extend %transition;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
    cursor: pointer;
    position: relative;
    padding-bottom: 3px;
    &:hover{
        color: $theme-color;
    }

    &.selected{
        color: $theme-color;
        &::before{
            width: 100%;
            background: currentColor;
        }
    }


    &::after{
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 50%;
        height: 2px;
        background: $theme-color;
        opacity: 0.75;
        @extend %transition;
    }
    &:hover{
        &::after{
            width: 100%;
        }
    }
}

.addButton {
    color: #FFF;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #b1b4c1;
    padding: 15px 40px;
    border-radius: 6px;
    font-weight: 600;
    @media #{$sm-layout} {
        padding: 9px 25px;
    }
    background-color:  $theme-color;;
    &:hover {
        background: #1f1f25;
        color: $theme-color;
        border-color: $theme-color;;
        box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-3px);   
  }


  .filterInput{
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
    &:focus {
        border-color: $theme-color;
    }

  }



}

